import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import PropTypes from "prop-types"
import { isEqual } from "lodash"

import Container from "../Layout/Container"
import PersonalDetails from "../PersonalDetails/PersonalDetails"
import ActionButtons from "../Elements/ActionButtons"

import {
  personalDetailsValidationSchema,
  personalDetailsWithBeneficiaryValidationSchema,
  // personalDetailsInitialValues,
} from "./utils/formData"
import { AppContext } from "../../context/AppContext"

/**
 * @param {string} backRoute string
 */

const PersonalDetailsForm = ({ backRoute, nextRoute }) => {
  const { state, dispatch } = useContext(AppContext)
  // const orderingForBeneficiary = state.orderingFor === "My Immediate Family"
  // const formValidationSchema =  personalDetailsValidationSchema

  const handleOnSubmit = async values => {
    if (state.flexmed.orderingFor === "Myself") {
      await dispatch({
        type: "SAVE_BENEFICIARY_DETAILS",
        payload: {
          beneficiaryFirstName: values.firstName,
          beneficiaryLastName: values.lastName,
          beneficiaryRelationship: "Self",
        },
      })
    }
    // await dispatch({ type: "SAVE_PERSONAL_DETAILS", payload: values })
    navigate(nextRoute)
  }

  return (
    <Container isCentered desktop={6}>
      <Formik
        initialValues={state.flexmed}
        onSubmit={handleOnSubmit}
        validationSchema={personalDetailsValidationSchema}
      >
        {({ values }) => {
          if (!isEqual(values, state.flexmed))
            dispatch({ type: "SAVE_PERSONAL_DETAILS", payload: values })

          return (
            <Form>
              <PersonalDetails values={values} />
              <ActionButtons
                submit={{ label: "Next" }}
                back={{ label: "Back", link: backRoute }}
              />
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

PersonalDetailsForm.propTypes = {
  backRoute: PropTypes.string,
}

export default PersonalDetailsForm
