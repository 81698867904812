import React from "react"
import Layout from "../../components/Layout/Layout"
import PersonalDetailsForm from "../../components/FlexMed/PersonalDetailsForm"

export default () => (
  <Layout
    title="Employee Details"
    subtitle="Please answer the following questions accurately. Your information will be used as a basis for your reimbursement."
    seoTitle="Employee Details"
    process="flexmed"
  >
    <PersonalDetailsForm
      backRoute="/flexmed/encode"
      nextRoute="/flexmed/summary"
    />
  </Layout>
)
